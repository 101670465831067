import React, { Component } from 'react'
import Layout from '../layout'
import { getTranslateFunction } from '../../utilities'
import { TranslatedLink } from '../TranslatedLink'
import ContactForm from '../contactForm/ContactForm'
import ReactVivus from 'react-vivus'
import LinkBtn from '../linkBtn/LinkBtn'
import MultiLayerTilt from '../MultiLayerTilt'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import SideMenuServices from '../sideMenu/side-menu-services'
import PageTitle from '../pageTitle'
import Reveal from '../reveal'
import './Pages.scss';
import ContaktUs from '../contactUs/ContactUs'

class ServicesDesignTemplate extends Component {

  render() {
    const { data, lang } = this.props
    const t = getTranslateFunction(lang)
    const design = data.design.childMarkdownRemark.frontmatter

    return (
      <Layout lang={lang} className={'mobile-menu-offset Design footer-hide'}>
        <section className="about-us-top margin-top">
          <div className="container">
            <div className="row subpage-title align-items-center fix-nav-offset">
              <div className="col-md-6 pr-0 pr-md-3 pr-lg-5">
                <PageTitle title={design.title} description={design.description} />
                <p>{design.subtitle}</p>
              </div>
              <div className="col-md-6 pl-md-3">
                <p className="description-smaller pr-0">{design.description}</p>
              </div>
            </div>
          </div>
        </section>

        <Reveal>
          <section className="references">
            <div className="d-md-flex">
              <div className="d-none d-lg-block col-lg-3 side-menu-height-reference" id="left">
                <SideMenuServices lang={lang} isGhost={false} />
              </div>
              <div className=" col-lg-9 our-services-block">
                {design.services && design.services.map((service, i) => {
                  const slug = service.service

                  const bgColor = service.background_color
                  const background = `linear-gradient(105deg, transparent 0%, transparent 65%, ${bgColor} 65%, ${bgColor} 100%)`

                  return (
                    <Reveal otherClassName="row align-items-center item" key={i}>
                      <div className="col-md-6">
                        <div className={'row'}>
                          <div className="col-xl-4 d-flex justify-content-md-center justify-content-lg-start">
                            <ReactVivus
                              className={'vivus'}
                              id={service.title + i}
                              option={{
                                start: 'inViewport',
                                delay: 0,
                                file: service.icon.publicURL,
                                animTimingFunction: 'EASE',
                                type: 'delayed',
                                duration: 100,
                              }}
                            // style={{ height: '256px', width: '256px', pointerEvents: 'none' }}
                            // style={{ height: '156px', width: '156px', pointerEvents: 'none' }}
                            />
                            {/*<img src={service.icon.publicURL} alt={service.title}/>*/}
                          </div>
                          <div className="col-xl-8">
                            <TranslatedLink to={slug}>
                              <h2>{service.title}</h2>
                            </TranslatedLink>
                            <p className="description-smaller">{service.subtitle}</p>
                            <p>{service.description}</p>
                            <LinkBtn className={'d-none d-md-flex'}>
                              <TranslatedLink to={slug}>{t('OurServices.OpenBtn')}</TranslatedLink>
                            </LinkBtn>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-5 push-lg-1 right">
                        <Link to={slug}>
                          <MultiLayerTilt layers={service.thumbnail_layers && service.thumbnail_layers.map((img, i) => (
                            <Img key={i} fluid={img.thumbnail.childImageSharp.fluid} alt={service.alt} />
                          ))} />
                        </Link>
                      </div>
                      <div className='col-12'>
                        <LinkBtn className={'d-flex d-md-none mt-3'}>
                          <TranslatedLink to={slug}>{t('OurServices.OpenBtn')}</TranslatedLink>
                        </LinkBtn>
                      </div>
                      <div className={'background-element'} style={{ background: background }} />
                    </Reveal>
                  )
                })}
              </div>
            </div>
          </section>
        </Reveal>

        <Reveal>
          <ContaktUs
            blockClassName={'services'}
            LeftSideTitle={t('References.MoreProjects.Title')}
            LeftSideSubTitle={'Servis'}
            LeftSideLink={'/en/services/service'}
            linkRight="/en/contact"
            rightSecondLink="/en/brief"
          />
        </Reveal>


    {/*     <Reveal>
          <ContactForm />
        </Reveal> */}
      </Layout>)
  }
}

export default ServicesDesignTemplate
